.aside {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 22rem;
	background: $white;
	border-left: 1px solid #eaebf7;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 5px 20px $black-1;
}

@media (min-width: 1600px) {
	body.aside-opened .aside {
		visibility: visible;
	}
}

.aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
}

.aside-footer {
	padding: 1rem 1.5rem;
	border-top: 1px solid #eaebf7;
}

.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid #eaebf7;
}

.header {
	padding-top: .75rem;
	padding-bottom: .75rem;
	width: 100%;
	z-index: 100;
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

@media print {
	.header {
		display: none;
	}
}

.header {
	.dropdown-menu {
		margin-top: 6px;
	}
	.mega-menu {
		width: 350px;
	}
}

.header-main {
	.top-bar .contact {
		/* margin: 9px 0; */
		/* padding-left: 15px; */
		/* padding: 9px; */
		li {
			margin: 0;
		}
	}
	.top-bar-right .custom {
		display: flex;
		/* margin: 10px 0; */
		li {
			padding: 10px;
			border-left: 1px solid #e6e7f5;
			&:last-child {
				margin-right: 0;
				border-right: 1px solid #e6e7f5;
			}
		}
	}
}

.ace-responsive-menu {
	margin-right: 1rem;
}

.header-main {
	.ace-responsive-menu li a {
		color: #2e384d;
		&:hover {
			color: $white;
		}
		&:focus {
			color: $black;
		}
	}
	.post-btn {
		float: right;
		margin-left: auto;
	}
}

.header-brand-footerimg {
	padding: 0 !important;
	border: 0 !important;
	width: auto !important;
	margin-bottom: 15px;
	height: 2.3rem;
}

.header-text1 .text-property {
	position: relative;
	top: -5px;
	font-family: 'Work Sans', sans-serif;
}

.header-text p {
	font-size: 20px;
	margin-top: 0.4rem;
	font-weight: 300;
}

.header-main-banner {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	z-index: 99;
	.horizontal-main,
	.horizontalMenu>.horizontalMenu-list {
		background: 0 0;
	}
}

@media (min-width: 992px) {
	.header-main-banner .horizontalMenu>.horizontalMenu-list>li>a {
		color: $white-8;
		>.fa {
			color: $white-8;
		}
	}
}

.horizontalMenu>.horizontalMenu-list,
.horizontal-header {
	background-color: transparent;
}

.horizontalMenu>.horizontalMenu-list>li {
	>a {
		color: $color;
		&.btn {
			color: $white;
			font-size: .85rem;
		}
		>.fa,
		&.active .fa {
			color: $white;
		}
	}
	&:hover>a>.fa {
		color: $white;
	}
	> {
		a:hover .fa {
			color: $white;
		}
		ul.sub-menu>li a i {
			color: #333;
		}
	}
}

.horizontal-main-1 {
	background: transparent;
	border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

.top-bar-left span,
.top-bar-right span {
	color: $white;
}

.top-bar-left .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: $white transparent transparent transparent;
}

.top-bar .contact i {
	color: $white !important;
}

.banner-top .header-main {
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 1024;
}

.header-white {
	display: none;
}

.header-main {
	background: $white;
}

.header-search {
	border-bottom: 1px solid transparent;
}

.header-style1 {
	.desktoplogo .header-brand-img1,
	.desktoplogo-1 .header-brand-img1 {
		display: none;
	}
}

.header-icons {
	.nav-link.icon {
		margin: 2px 5px;
		border-radius: 50%;
		padding: 8px;
		width: 2.2rem;
		text-align: center;
		height: 2.2rem;
		font-size: 15px;
		position: relative;
		font-size: 18px;
		border-radius: 50px;
		background: $background;
		border: 1px solid #eaebf7;
		box-shadow: 0 5px 10px rgba(245, 246, 253, 0.5);
		i {
			color: #566580;
		}
	}
	.nav-unread.badge {
		position: absolute;
		top: -2px;
		right: -2px;
		text-align: center;
		font-size: 10px;
		line-height: 1;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
	}
	.cart-dropdown {
		min-width: 22rem;
		img {
			max-width: 3.5rem;
			width: 100%;
		}
	}
	.header-user {
		width: 35px;
		height: 35px;
		border-radius: 50px;
		background: $background;
		border: 1px solid #eaebf7;
		box-shadow: 0 5px 10px rgba(245, 246, 253, 0.5);
	}
	.profile-1 .nav-link.user-profile {
		margin: 2px 5px;
		padding: 0;
	}
}

.header-dropdown-list {
	height: 300px;
}
.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}

.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
	.header-brand-img {
		margin-right: 0;
	}
}

.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
	margin-top: -1.5px;
	.header-brand-img {
		margin-right: 0;
	}
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: .5rem;
	width: auto;
}

.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
}

.center-block {
	margin-right: auto;
	margin-left: auto;
	float: inherit !important;
}

.header-btn.has-new {
	position: relative;
	&:before {
		content: '';
		width: 6px;
		height: 6px;
		background: $danger;
		position: absolute;
		top: 4px;
		right: 4px;
		border-radius: 50%;
	}
}

.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: #2c2c2c;
	&:hover {
		color: #2c2c2c;
	}
}

.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 4px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

.user-img {
	margin-top: 2px;
}

.header {
	.nav-item .badge,
	.nav-link .badge {
		position: absolute;
		top: 0;
		right: -1px;
		padding: .2rem .25rem;
		min-width: 1rem;
		font-size: 13px;
	}
}

#headerMenuCollapse {
	.nav-item .badge,
	.nav-link .badge {
		position: relative;
		min-width: 1rem;
		font-size: 10px;
		font-weight: 500;
	}
}
.header-main .social-icons {
	float: right;
	li {
		color: #212529;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #212529;
		}
	}
}

.header-search {
	padding: 1.5rem 1.5rem;
	.header-icons .header-icons-link {
		display: inline-flex;
		margin-bottom: 0;
	}
}

.header-icons-link .header-icons-link1 i {
	color: #636d8c;
}

.header-search .header-icons {
	.header-icons-link {
		li a {
			background: $black-2;
			width: 40px;
			height: 40px;
			border-radius: 50px;
			text-align: center;
			line-height: 2;
			margin-right: 5px;
			display: inline-block;
			margin-top: 4px;
		}
		.header-icons-link1,
		.header-icons-link2 {
			width: 2.2rem;
			text-align: center;
			height: 2.2rem;
			position: relative;
			line-height: 2.2;
			vertical-align: middle;
			font-size: 18px;
			border-radius: 4px;
			margin-top: 2px;
		}
		.header-icons-link1 img {
			padding: 7px;
		}
	}
	.main-badge1 {
		position: absolute;
		top: -5px;
		right: -5px;
		text-align: center;
		font-size: 10px;
		padding: 3px 5px !important;
	}
	.header-icons-link.icons li {
		background: $white;
	}
}

.top-bar {
	border-bottom: 1px solid $black-2;
	background: $black-1;
}

.top-bar-left ul {
	margin-bottom: 0;
}

.top-bar {
	.top-bar-right {
		float: right;
	}
	.top-bar-left {
		display: flex;
		.socials li {
			display: inline-block;
			float: left;
			font-size: 15px;
			padding: 9px 15px 8px 15px;
			margin: 0;
			border-left: 1px solid #e6e7f5;
			cursor: pointer;
			margin: 0 !important;
		}
		.contact li {
			display: inline-block;
			color: #2e384d;
			border-left: 1px solid #e6e7f5;
			padding: 9px 15px 8px 15px;
			margin: 0;
			&:last-child {
				border-right: 1px solid #e6e7f5;
			}
		}
	}
}


.top-bar .top-bar-right {
	display: flex;
}

.header-search {
	.header-inputs .input-group-append.searchicon {
		top: 10px;
		right: 25px;
		position: relative;
	}
	.header-search-logo {
		margin-right: 1rem;
		z-index: 1;
	}
	.header-nav .nav-cart .icon-cart {
		i {
			font-size: 18px;
			color: #636d8c;
		}
		a {
			color: #636d8c;
		}
	}
}

.header-nav {
	display: flex;
}

.header-links {
	padding: 3px;
	li {
		margin: 5px;
		a {
			padding: 9px 18px;
			border-radius: 4px;
		}
	}
}

.header-style .horizontalMenu {
	float: inherit !important;
}

.top-bar {
	.select2-dropdown.select2-dropdown--below {
		width: 180px !important;
	}
	.select-country .select2-container {
		width: 150px !important;
	}
}

